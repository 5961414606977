.is-logo {
  &.has-max-width {
    max-height: 7rem;
  }

  .logo {
    height: 100%;
    max-height: 7rem;
    max-width: 7rem;
    background-color: gray;
  }
}
.is-preview {
  &.has-max-width {
    max-height: 7rem;
  }

  .preview {
    height: 100%;
    max-height: 7rem;
    max-width: 7rem;
  }
}

@media screen and (max-width: 768px) {
  div.logo {
    display: none;
  }
  div.preview {
    display: none;
  }
}
